import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { localize } from '../helpers/helpers'

import SEO from "../components/seo"
import Info from "../components/contact/info"
import Form from "../components/contact/form"

const ContactPage = ({ data, pageContext }) => {
  const
    info = localize(data.sanityContact, [pageContext.language]),
    { title, image, _rawText, subtitle, address1, address2, address3, email  } = info,
    { t } = useTranslation('contact');

  useEffect(() => {
    const wrapper = document.querySelector('#gatsby-focus-wrapper')
    wrapper.style.backgroundColor = "#EDEBE8"
  })

  return (
    <>
      <SEO title={t('contact')} lang={pageContext.language}/>
      <div className='grid title-container' data-scroll-section>
        <h1
          data-scroll
          className="page-title"
          style={{maxWidth: '663px'}}
          data-sal='fade' data-sal-duration='750'
          data-sal-easing='ease'
        >
          {title.translate}
        </h1>
      </div>
      <Info title={subtitle.translate} image={image} text={_rawText}/>
      <Form language={pageContext.language}/>
      <div className="contact-info grid" data-scroll-section>
        <div className="left" data-scroll >
          <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>{t('visit')}</h3>
          <p data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease'>{address1}</p>
          <p data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease'>{address2}</p>
          <p data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease'>{address3}</p>
        </div>
        <div className="right" data-scroll>
          <h3 data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>{t('contact')}</h3>
          <p data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease'><a href='mailto:press@arquetipoestudio.com'>{email}</a></p>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ContactPage($language: String) {
    sanityContact {
      title {
        translate(language: $language)
      }
      subtitle {
        translate(language: $language)
      }
      image {
        asset {
          fluid(maxWidth:980) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      _rawText
      address1
      address2
      address3
      email
    }
  }
`

export default ContactPage
