import React from 'react'

import Image from '../image'
import BlockContent from '../block-content'

const Info = ({ title, image, text }) => {
  return (
    <div className="contact-info-container grid" data-scroll-section>
      <div className="text-container" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <h2 data-sal='fade' data-sal-delay="350" data-sal-duration='750' data-sal-easing='ease'>{title}</h2>
        <BlockContent blocks={text || []} className="block-content"/>
      </div>
      <div className="contact-image" data-scroll data-scroll-speed="1" data-sal='fade' data-sal-duration='750' data-sal-easing='ease'>
        <Image fluid={image.asset.fluid}/>
      </div>
    </div>
  )
}

export default Info
