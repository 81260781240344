import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const Form = ({ language }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const dateInput = require("configurable-date-input-polyfill");
    }
  })

  const
    { t, i18n } = useTranslation('contact'),
    minDate = moment().format("YYYY-MM-DD"),
    [selectedDate, setDate] = useState(minDate);

  const dateChange = (e) => {
    const { value } = e.target
    setDate(moment(value).format("YYYY-MM-DD"))
  }

  return (
    <div className="form-container grid" data-scroll-section>
      <form name='Formulario-GutiérrezF-Website' method='post' action={`/${i18n.language}/success`} data-netlify='true' data-netlify-honeypot='bot-field' autoComplete='off'>
        <input type='hidden' name='bot-field'/>
        <input type='hidden' name='form-name' value='Formulario-GutiérrezF-Website'/>

        <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="form-subtitle">{t('personal')}</p>
        <fieldset className="name-container">
          <label data-sal='fade' data-sal-duration='750' data-sal-easing='ease' htmlFor="name">{t('name')}</label>
          <input data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease' type="text" name="name" required placeholder="Fernando Gutiérrez"/>
        </fieldset>
        <fieldset className="mail-container">
          <label data-sal='fade' data-sal-duration='750' data-sal-easing='ease' htmlFor="mail">{t('mail')}</label>
          <input data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease' type="email" name="mail" required placeholder="hola@gutierrezf.com"/>
        </fieldset>
        <fieldset className="phone-container">
          <label data-sal='fade' data-sal-duration='750' data-sal-easing='ease' htmlFor="phone">{t('phone')}</label>
          <input data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease' type="text" name="phone" required placeholder="55 7889 9888"/>
        </fieldset>
        <p data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="form-subtitle event">{t('event')}</p>
        <fieldset className="type-container">
          <label data-sal='fade' data-sal-duration='750' data-sal-easing='ease' htmlFor="type-of-event">{t('type')}</label>
          <input data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease' type="text" name="type-of-event" required placeholder={t('type-placeholder')}/>
        </fieldset>
        <fieldset className="location-container">
          <label data-sal='fade' data-sal-duration='750' data-sal-easing='ease' htmlFor="location">{t('location')}</label>
          <input data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease' type="text" name="location" required placeholder={t('location-placeholder')}/>
        </fieldset>
        <fieldset className="message-container">
          <label data-sal='fade' data-sal-duration='750' data-sal-easing='ease' htmlFor="message">{t('message')}</label>
          <textarea data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease' name="message" placeholder={t('mess-placeholder')}/>
        </fieldset>
        <fieldset className="date-container">
          <label data-sal='fade' data-sal-duration='750' data-sal-easing='ease' htmlFor="date">{t('date')}</label>
          <input data-sal='fade' data-sal-duration='750' data-sal-delay="250" data-sal-easing='ease'
            required
            type="date"
            name="date"
            min={minDate}
            lang={language}
            value={selectedDate}
            onChange={(e) => dateChange(e)}
            data-first-day="mo"
            placeholder={t('datePlaceholder')}
          />
        </fieldset>
        <button type="submit">{t('button')}</button>
      </form>
    </div>
  )
}

export default Form
